/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Spinner, jsx, Grid } from "theme-ui"
import { FaArrowRight } from "react-icons/fa"
import { callApi } from "../../utils"
import { navigate, Link } from "gatsby"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const EventsGrid = () => {
  const [events, setEvents] = useState("loading")
  const [errorState, setErrorState] = useState(undefined)
  const today = new Date()
  const isoDate = today.toISOString()

  useEffect(() => {
    callApi(`/events?_where[Date_gte]=${isoDate}`, "GET")
      .then(res => {
        // console.log("res length", res.length)
        if (res.length === 0) {
          setEvents("empty")
        } else {
          setEvents(res)
        }
      })
      .catch(err => {
        setEvents("error")
        setErrorState(err)
      })
  }, [])

  // console.log("events", events)

  if (events === "loading") {
    return (
      <>
        <p>Loading ...</p>
        <Spinner sx={{ color: "red" }} />
      </>
    )
  } else if (events === "empty") {
    return (
      <>
        <p>There are current no events</p>
      </>
    )
  } else if (events === "error") {
    return (
      <>
        <p sx={{ color: "red" }}>{errorState} </p>
      </>
    )
  } else {
    return (
      <>
        <Grid gap={2} columns={[1, 2, 4]} sx={styles} autoFill>
          {events.map((event, index) => {
            //format date
            const date = new Date(event.Date)
            const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            const readableDate = dateTimeFormat.format(date)

            const eventType = event.Type.replace(/_|-/g, " ")
            //careful annoying capital letters in fields
            return (
              <Link
                to={`/app/event/${event.slug}`}
                key={`event${event.id}`}
                className="event"
              >
                <div className="eventImage">
                  <img src={`${API_URL}${event.Img.url}`} alt={event.Img_alt} />
                  <div className="arrow">
                    <FaArrowRight />
                  </div>
                </div>
                <div className="eventDetails">
                  <h1>{event.Title}</h1>
                  <h2>{readableDate}</h2>
                  <h3>{eventType}</h3>
                  {event.Time && (
                    <ul>
                      <li>{event.Time}</li>
                    </ul>
                  )}
                </div>
              </Link>
            )
          })}
        </Grid>
      </>
    )
  }
}

export default EventsGrid

const styles = {
  "& .event": {
    textDecoration: "none",
    "& .eventImage": {
      position: "relative",
      overflow: "hidden",
      height: "400px",
      img: {
        objectFit: "cover",
        maxWidth: "100%",
        height: "400px",
        transition: "all .3s ease",
      },
    },
    "& .arrow": {
      width: "50px",
      height: "50px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 4,
      bottom: "0",
      transition: "background .3s ease",
      "& svg": {
        position: "relative",
        color: "accent",
        fontSize: 5,
      },
    },
    "& .eventDetails": {
      transition: "background .3s ease",
      "& h1": {
        color: "black",
        fontSize: 3,
        mt: 1,
        mb: 0,
        fontWeight: "700",
      },
      "& h2": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "muted",
        fontWeight: "400",
      },
      "& h3": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "accent",
        fontWeight: "400",
      },
      "& ul": {
        pl: 0,
        mt: 0,
        listStyle: "none",
        color: "muted",
      },
      "& li::before": {
        content: '"• "',
        color: "accent",
      },
    },
    //event hover
    "&:hover": {
      "& .eventImage": {
        img: {
          transform: "scale(1.2)",
        },
        "& .arrow": {
          background: "black",
        },
      },
    },
  },
}
