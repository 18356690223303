/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Spinner, jsx, Grid } from "theme-ui"
import { FaArrowRight } from "react-icons/fa"
import { callApi } from "../../utils"
import { navigate, Link } from "gatsby"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const GiveawaysGrid = () => {
  const [giveaways, setGiveaways] = useState("loading")
  const [errorState, setErrorState] = useState(undefined)
  const today = new Date()
  const isoDate = today.toISOString()
  useEffect(() => {
    callApi(`/ticket-giveaways?_where[expiry_date_gte]=${isoDate}`, "GET")
      .then(res => {
        // console.log("res length", res.length)
        if (res.length === 0) {
          setGiveaways("empty")
        } else {
          setGiveaways(res)
        }
      })
      .catch(err => {
        setGiveaways("error")
        setErrorState(err)
      })
  }, [])

  // console.log("giveaways", giveaways)

  if (giveaways === "loading") {
    return (
      <>
        <p>Loading ...</p>
        <Spinner sx={{ color: "red" }} />
      </>
    )
  } else if (giveaways === "empty") {
    return (
      <>
        <p>There are currently no Giveaways</p>
      </>
    )
  } else if (giveaways === "error") {
    return (
      <>
        <p sx={{ color: "red" }}>{errorState} </p>
      </>
    )
  } else {
    return (
      <>
        <Grid gap={2} columns={[1, 2, 4]} sx={styles}>
          {giveaways.map(giveaway => {
            return (
              <Link
                to={`/app/giveaway/${giveaway.slug}`}
                key={`giveaway${giveaway.id}`}
                className="giveaway"
              >
                <div className="giveawayImage">
                  <img
                    src={`${API_URL}${giveaway.image.img_src.url}`}
                    alt={giveaway.image.img_alt}
                  />
                  <div className="arrow">
                    <FaArrowRight />
                  </div>
                </div>
                <div className="giveawayDetails">
                  <h1>{giveaway.title}</h1>
                </div>
              </Link>
            )
          })}
        </Grid>
      </>
    )
  }
}

export default GiveawaysGrid

const styles = {
  "& .giveaway": {
    textDecoration: "none",
    "& .giveawayImage": {
      position: "relative",
      overflow: "hidden",
      height: "400px",
      img: {
        objectFit: "cover",
        maxWidth: "100%",
        height: "400px",
        transition: "all .3s ease",
      },
    },
    "& .arrow": {
      width: "50px",
      height: "50px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 4,
      bottom: "0",
      transition: "background .3s ease",
      "& svg": {
        position: "relative",
        color: "accent",
        fontSize: 5,
      },
    },
    "& .giveawayDetails": {
      transition: "background .3s ease",
      "& h1": {
        color: "black",
        fontSize: 3,
        mt: 1,
        mb: 0,
        fontWeight: "700",
      },
      "& h2": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "muted",
        fontWeight: "400",
      },
      "& h3": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "accent",
        fontWeight: "400",
      },
      "& ul": {
        pl: 0,
        mt: 0,
        listStyle: "none",
        color: "muted",
      },
      "& li::before": {
        content: '"• "',
        color: "accent",
      },
    },
    //giveaway hover
    "&:hover": {
      "& .giveawayImage": {
        img: {
          transform: "scale(1.2)",
        },
        "& .arrow": {
          background: "black",
        },
      },
    },
  },
}
