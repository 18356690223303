/** @jsx jsx */
import { darken, lighten } from "@theme-ui/color"
import React, { useEffect, useState } from "react"
import { Spinner, Select, Button, jsx } from "theme-ui"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import { callApi } from "../../../utils"
import { useCurrentUser, useDispatchCurrentUser } from "../../CurrentUser"
import { navigate, Link } from "gatsby"
import { toast } from "react-toastify"

export default props => {
  const stripe = useStripe()
  const elements = useElements()

  const [token, setToken] = useState(null)
  const [total, setTotal] = useState("loading")
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)

  let currentUser = useCurrentUser()

  useEffect(() => {
    const loadToken = async () => {
      setLoading(true)
      const response = await callApi(`/subscriptions/payment`, "POST", {
        userId: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        subscriptionTier: props.subscriptionTier,
      })

      // console.log("response", response)
      setToken(response.client_secret)
      //setTotal(response.amount)
      setTotal("60.00")

      setLoading(false)
      // console.log("currentUser", currentUser)
    }

    loadToken()
  }, [])

  const handleCancel = () => {
    //calls in parent for form to be closed
    props.onChange()
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    setSuccessMsg("")
    setErrMsg("")
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    //console.log('token', token)
    const result = await stripe.confirmCardPayment(token, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })
    if (result.error) {
      // console.log("handleSubmit error", result)
      setLoading(false)
      setErrMsg("There was a problem with your payment.")
      toast.error(
        `🧨 There was a problem with your payment - "${result.error.message}"`,
        { autoClose: false }
      )
      return
    }
    //console.log("handleSubmit success", result)
    setLoading(false)
    // setSuccessMsg("Your payment was successful, redirecting you to user dashboard ... ")
    toast.success(
      "😀 Your payment was successful, redirecting you to user dashboard."
    )
    //Goal: YYYY-MM-DD
    var today = new Date()
    //month plus one to take it out of 0 base
    // var date = ((today.getFullYear()+1)+'-'+('0'+(today.getMonth()+1)).slice(-2)+'-'+today.getDate());
    // const isoDate = date.toISOString()
    var dd = String(today.getDate()).padStart(2, "0")
    var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = today.getFullYear() + 1
    const date = `${yyyy}-${mm}-${dd}`
    let dateReadable = new Date(date)
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    dateReadable = dateTimeFormat.format(dateReadable)
    const totalInPounds = (props.total / 100).toFixed(2)
    //console.log('date test:', date)
    //add subscription
    const checkBody = {
      user: currentUser.id,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      paymentIntent: result.paymentIntent.id,
      total_in_pence: result.paymentIntent.amount,
      expiry_date: date,
    }
    // console.log("checkBody", checkBody)
    const response = await callApi(`/subscriptions`, "POST", {
      user: currentUser.id,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      paymentIntent: result.paymentIntent.id,
      total_in_pence: result.paymentIntent.amount,
      expiry_date: date,
      expiry_date_email: dateReadable,
      email: currentUser.email,
      total: props.total, //passed through not used in processing
      total_in_pounds: totalInPounds,
      description: `${currentUser.firstName} ${currentUser.lastName} Subscription Confirmation`,
      tier: props.subscriptionTierReadable,
    })
    // console.log("subscription response", response)
    // //update user status to subscribed
    const updateUserResponse = await callApi(
      `/users/${currentUser.id}`,
      "PUT",
      {
        userStatus: props.subscriptionTier,
      }
    )
    // console.log("updateUserResponse", updateUserResponse)
    currentUser.userStatus = props.subscriptionTier

    navigate("/app")
  }

  return (
    <div sx={styles}>
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "18px",
                color: "#424770",
              },
            },
          }}
          disabled={loading}
        />
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="submit" disabled={!stripe || loading}>
          Buy Subscription
        </Button>
        {loading && <Spinner title="loading" />}
      </form>
      <div>
        {errMsg && <p className="error">{errMsg}</p>}
        {successMsg && (
          <p>
            {successMsg}{" "}
            <span aria-hidden="true" role="img">
              😊
            </span>
          </p>
        )}
      </div>
    </div>
  )
}

const styles = {
  "& button": {
    mt: 3,
    mr: 2,
  },
  "& .StripeElement": {
    border: "1px black solid",
    padding: 2,
    borderRadius: "4px",
  },
  "& .error": {
    color: "red",
  },
}
