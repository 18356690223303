/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Spinner, jsx, Box, Button } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import OffersGrid from "./OffersGrid"
import { navigate } from "gatsby"
import { callApi } from "../../utils"
import SanitizedHTMLBodyCopy from "../sanitizedHTMLBodyCopy"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"
const Offer = ({ offerTitle, location }) => {
  const [offerData, setOfferData] = useState("loading")

  useEffect(() => {
    callApi(`/ticket-offers/${offerTitle}`, "GET")
      .then(res => {
        // console.log("res", res)
        setOfferData(res)
      })
      .catch(err => {
        setOfferData("error")
      })

    //console.log("location", location)
  }, [location, offerTitle])

  if (offerData === "loading") {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  } else if (offerData === "error") {
    navigate("/app?error=no-offer")
    return null
  } else {
    // console.log("offerData", offerData)
    //sort image
    let offerImage = offerData.image.img_src?.formats?.medium
      ? offerData.image.img_src?.formats?.medium.url
      : offerData.image.img_src.url
    return (
      <Box className="offer" sx={styles}>
        <Box className="offerHeader">
          <Box className="offerDetails">
            <h1>Ticket Offer - {offerData.title}</h1>
            <Box className="offerInfo">
              <SanitizedHTMLBodyCopy content={offerData.offer} />
            </Box>
            <a href={offerData.ticket_url} className="button" target="_blank">
              Claim Offer
            </a>
          </Box>

          <Box className="offerImage">
            <img
              src={`${API_URL}${offerImage}`}
              alt={offerData.image.img_alt}
            />
          </Box>
        </Box>

        <Box className="offerText">
          <div>
            <SanitizedHTMLBodyCopy content={offerData.description} />
            <a href={offerData.ticket_url}>Go To Offer Website</a>
          </div>
        </Box>
        <div className="moreOffers">
          <h1>More Ticket Offers</h1>
          <OffersGrid skip={offerTitle} />
        </div>
      </Box>
    )
  }
}

export default Offer

const styles = {
  "&.offer": {
    mb: 5,

    ".offerHeader": {
      display: "flex",
      flexWrap: "wrap",
      flexDirecton: ["column", "row"],
      ".offerImage": {
        width: ["100%", null, "40%"],
        maxHeight: ["300px", "350px", "400px"],
        pr: 3,
        img: {
          maxWidth: ["100%", "100%", "90%"],
          maxHeight: "100%",
        },
        order: [1, 1, 2],
      },
      ".offerDetails": {
        order: [2, 2, 1],
        width: ["100%", null, "60%"],
        pr: 3,
        h1: {
          ontSize: 6,
          fontWeight: 700,
          mb: 0,
        },
        h2: { color: "muted", mt: 1 },
        "a.button": {
          textDecoration: "none",
          px: 3,
          py: 2,
          bg: "accent",
          color: "white",
          cursor: "pointer",
          borderRadius: "4px",
          transition: "background 0.3s ease-in-out",
          "&:hover": {
            background: darken("accent", 0.1),
          },
          ".offerInfo": {
            display: "flex",
            div: {
              mr: 4,
            },
            h4: {
              color: "muted",
              mb: 0,
              fontWeight: "normal",
            },
            p: {
              color: "muted",
              mt: 2,
              fontWeight: "normal",
              fontSize: 3,
            },
          },
        },
      },
    },
    ".offerText": {
      fontSize: 3,
      color: "muted",
      pl: 2,
      mb: 4,
      a: {
        color: "accent",
      },
    },
    ".book-btn:disabled": {
      opacity: 0.3,
    },
    ".message": {
      borderLeftColor: "accent",
      bg: "#ffb6b6",
      display: "inline-block",
    },
  },
  ".moreOffers": {
    mb: 4,
    mt: 3,
  },
}
