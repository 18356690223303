/** @jsx jsx */
import React, { useEffect, useState, useCallback } from "react"
import { Spinner, jsx, Box, Select, Button, Label, Message } from "theme-ui"
import GiveawaysGrid from "./GiveawaysGrid"
import GiveawayForm from "./forms/GiveawayForm"
import { navigate } from "gatsby"
import { callApi } from "../../utils"
import SanitizedHTMLBodyCopy from "../sanitizedHTMLBodyCopy"
import { toast } from "react-toastify"
import { Collapse } from "react-collapse"
import { FaArrowDown } from "react-icons/fa"
import ShareIcons from "../shareIcons"
import FAQs from "../FAQs"

import { useCurrentUser } from "../CurrentUser"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const Event = ({ eventTitle, pageURL, location }) => {
  const [eventData, setEventData] = useState("loading")
  const [archive, setArchive] = useState(false)
  const [previousBooking, setPreviousBooking] = useState(false)
  const [reservedEvent, setReservedEvent] = useState(false)
  const [eventOpen, setEventOpen] = useState(true)
  const [errMsg, setErrMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  //this is to prevent double submits
  const [submitted, setSubmitted] = useState(false)

  let currentUser = useCurrentUser()

  const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false)
  const onClick = useCallback(
    () => setIsButtonCollapseOpen(!isButtonCollapseOpen),
    [isButtonCollapseOpen]
  )

  useEffect(() => {
    callApi(`/events/${eventTitle}`, "GET")
      .then(res => {
        // console.log("res", res)
        setEventData(res)
        const today = new Date()
        console.log("today", today)
        const eventDate = new Date(res.Date)
        //move eventDate to be at 8pm so tickets still available up till then
        eventDate.setHours(20, 0, 0, 0)
        console.log("eventDate", eventDate)
        if (res.reserved_event === true) {
          setReservedEvent(true)
        }
        if (res.Open === false) {
          setEventOpen(false)
        }
        if (today.valueOf() > eventDate.valueOf()) {
          setArchive(true)
        } else {
          //are they already booked?
          const eventId = res.id
          const userId = currentUser.id
          callApi(
            `/attendees?event_id=${eventId}&users_id=${userId}`,
            "GET"
          ).then(booked => {
            // console.log("booked", booked)
            if (booked.length > 0) {
              // console.log("you have booked previously")
              if (booked[0]["status"] === "cancelled") {
                setPreviousBooking("cancelled")
              } else if (booked[0]["status"] === "reserved_accepted") {
                setPreviousBooking("reserved_accepted")
              } else if (booked[0]["status"] === "reserved_rejected") {
                setPreviousBooking("reserved_rejected")
              } else {
                setPreviousBooking(true)
              }
            }
          })
        }
      })
      .catch(err => {
        setEventData("error")
      })
  }, [])

  const handleSubmit = async event => {
    event.preventDefault()
    setSubmitted(true)
    let attendeeStatus = "accepted"
    if (reservedEvent) {
      attendeeStatus = "reserved"
    }
    try {
      //get eventType without underscores
      const eventType = eventData.Type.replace(/_|-/g, " ")
      //sort date
      const date = new Date(eventData.Date)
      const dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
      const eventDate = dateTimeFormat.format(date)
      //add attendee
      const attendeeResponse = await callApi(`/attendees`, "POST", {
        users_id: currentUser.id,
        user_name: `${currentUser.firstName} ${currentUser.lastName}`,
        user_type: currentUser.userStatus,
        status: attendeeStatus,
        event_name: eventData.Title,
        event_id: eventData.id,
        eventURL: pageURL,
        max_attendees: eventData.max_attendees,
        description: `${eventData.Title} - ${eventType}`,
        event_date: eventDate,
        event_time: eventData.Time,
        event_venue: eventData.Venue,
        email: currentUser.email,
      })
      if (reservedEvent) {
        setSuccessMsg(
          "Reservation Successful.  You will receive a confirmation email with further details"
        )
        toast.success(
          "🧨 Reservation Successful.  You will receive a confirmation email with further details",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
        navigate("/app")
      } else {
        setSuccessMsg(
          "Booking Successful.  You will receive a confirmation email with further details"
        )
        toast.success(
          "😀 Booking Successful.  You will receive a confirmation email with further details",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
        navigate("/app")
      }

      //console.log("attendeeResponse", attendeeResponse)
    } catch (error) {
      //setErrMsg(error)
      // console.log('error', error)
      toast.error(`🧨 ${error}`, {
        autoClose: false,
      })
      setSubmitted(false)
    }
  }

  //return
  if (eventData === "loading") {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  } else if (eventData === "error") {
    navigate("/app?error=no-event")
    toast.error(`🧨 Sorry, that event doesn't exist.`, {})
    return null
  } else {
    // console.log("eventData", eventData)
    //sort date
    const date = new Date(eventData.Date)
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    //sort image
    let eventImage = eventData.Img?.formats?.medium
      ? eventData.Img.formats.medium.url
      : eventData.Img.url
    // console.log("eventImage", eventImage)
    //remove underscores from type
    const eventType = eventData.Type.replace(/_|-/g, " ")
    return (
      <Box className="event" sx={styles}>
        <Box className="eventHeader">
          <Box className="eventDetails">
            <h1>{eventData.Title}</h1>
            <h2>{eventType}</h2>
            <Box className="eventInfo">
              <Box>
                <h4>Date</h4>
                <p>{dateTimeFormat.format(date)}</p>
              </Box>
              <Box>
                <h4>Time</h4>
                <p>{eventData.Time}</p>
              </Box>
              <Box>
                <h4>Venue</h4>
                <p>{eventData.Venue}</p>
              </Box>
            </Box>

            {archive ? (
              <Button disabled={true}>Archive Event</Button>
            ) : (
              <>
                {previousBooking ? (
                  <>
                    {reservedEvent ? (
                      <Message className="message">
                        We have received your application to attend this event,
                        you should receive an email soon when we have chosen the
                        members to attend.
                      </Message>
                    ) : (
                      <>
                        {previousBooking === "cancelled" ? (
                          <Message className="message">
                            You cancelled your place at this event, if this is
                            an error please contact us
                          </Message>
                        ) : (
                          <Message className="message">
                            You are booked on this event
                          </Message>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {eventOpen ? (
                      <>
                        {reservedEvent ? (
                          <div
                            sx={{
                              display: "flex",
                              "& svg": {
                                color: "accent",
                                width: "28px",
                                mx: 2,
                                my: 0,
                              },
                            }}
                          >
                            <Button
                              onClick={handleSubmit}
                              //disabled={successMsg}
                              disabled={submitted}
                              className="book-btn"
                            >
                              Reserve A Place
                            </Button>
                            {submitted && <Spinner title="loading" />}
                          </div>
                        ) : (
                          <div
                            sx={{
                              display: "flex",
                              "& svg": {
                                color: "accent",
                                width: "28px",
                                mx: 2,
                                my: 0,
                              },
                            }}
                          >
                            <Button
                              onClick={handleSubmit}
                              //disabled={successMsg}
                              disabled={submitted}
                              className="book-btn"
                            >
                              Book A Place
                            </Button>
                            {submitted && <Spinner title="loading" />}
                          </div>
                        )}
                      </>
                    ) : (
                      //temp removed
                      // <Message className="message">
                      //   Booking Closed for this event
                      // </Message>
                      <span></span>
                    )}
                  </>
                )}
              </>
            )}

            {/* {eventData.fa_q && (
              <a
                href="#faqs"
                className="book-btn"
                sx={
                  {
                    // variant: "buttons.primary",
                  }
                }
              >
                FAQ's
              </a>
            )} */}

            {successMsg && <p sx={{ color: "red" }}>{successMsg}</p>}
            {errMsg && <p sx={{ color: "red" }}>{errMsg}</p>}
          </Box>
          <Box className="eventImage">
            <img src={`${API_URL}${eventImage}`} alt={eventData.Img_alt} />
          </Box>
        </Box>

        <Box className="eventText">
          <SanitizedHTMLBodyCopy content={eventData.Description} />
        </Box>
        {previousBooking === "reserved_accepted" && (
          <Box className="eventText">
            <SanitizedHTMLBodyCopy content={eventData.attendee_event_info} />
          </Box>
        )}
        {eventData.Biog && (
          <Box
            sx={{
              "& .bodyCopy": {
                fontSize: 4,
                color: "muted",
                "h1,h2,h3,h4,h5,h6": {
                  fontSize: 5,
                },
                "& a": {
                  color: "accent",
                },
                "& img": {
                  width: "100%",
                },
              },
              mb: 3,
            }}
          >
            <a
              aria-controls="open biog"
              aria-expanded={isButtonCollapseOpen}
              onClick={onClick}
              type="button"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                "& h3 svg.icon": {
                  fontSize: 2,
                  transform: "rotate(-90deg)",
                  transition: "all 0.2s ease-in-out",
                },
                "& h3 svg.icon.true": {
                  transform: "rotate(0deg)",
                },
              }}
            >
              <h3>
                Biog <FaArrowDown className={`icon ${isButtonCollapseOpen}`} />
              </h3>
            </a>
            <Collapse isOpened={isButtonCollapseOpen}>
              <SanitizedHTMLBodyCopy content={eventData.Biog} />
            </Collapse>
          </Box>
        )}
        {eventData.fa_q && (
          <section sx={{ mx: [2, 3, 4] }} id="faqs">
            <FAQs content={eventData.fa_q} />
          </section>
        )}
        {/* <div>
                  <h1>Current Giveaways</h1>
                  <GiveawaysGrid />
                  </div> */}
      </Box>
    )
  }
}

export default Event

const styles = {
  "&.event": {
    mb: 5,

    ".eventHeader": {
      display: ["flex", "flex"],
      flexWrap: "wrap",
      flexDirecton: ["column", "row"],
      ".eventImage": {
        width: ["100%", null, "40%"],
        pr: 3,
        img: {
          maxWidth: ["100%", null, "90%"],
        },
        order: [1, 1, 2],
      },
      ".eventDetails": {
        width: ["100%", null, "60%"],
        pr: 3,
        h1: {
          fontSize: [5, 6],
          fontWeight: 700,
          mb: 0,
        },
        order: [2, 2, 1],
        h2: { color: "muted", mt: 1 },
        ".eventInfo": {
          order: [3, 2, 2],
          display: "flex",
          div: {
            mr: 4,
          },
          h4: {
            color: "muted",
            mb: 0,
            fontWeight: "normal",
          },
          p: {
            color: "accent",
            mt: 1,
            fontWeight: "bold",
            fontSize: 3,
          },
        },
      },
    },
    ".eventText": {
      fontSize: 4,
      color: "muted",
      pl: 2,
    },
    ".book-btn:disabled": {
      opacity: 0.3,
    },
    ".message": {
      borderLeftColor: "accent",
      bg: "#ffb6b6",
      display: "inline-block",
    },
  },
}
