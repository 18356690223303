/** @jsx jsx */
import React, { useEffect, useRef, useState } from "react"
import { Flex, jsx, Box, Select, Button, Label, Message } from "theme-ui"
import GiveawaysGrid from "./GiveawaysGrid"
import GiveawayForm from "./forms/GiveawayForm"
import { navigate } from "gatsby"
import { callApi } from "../../utils"
import SanitizedHTMLBodyCopy from "../sanitizedHTMLBodyCopy"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { useCurrentUser } from "../CurrentUser"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"
const stripe = loadStripe(
  "pk_live_51IxDSWFjBziK5zZO2uSIW5uSfHKdhA9N0M1DdukHFJwDu0TxaUxPFIMRVR85OF01Mh8g52x47DxrcK5FZlYQxwXR00hnwN0hVs"
)

const Giveaway = ({ giveawayTitle, pageURL }) => {
  const [giveawayData, setGiveawayData] = useState("loading")
  const [buyState, setBuyState] = useState(false)
  const [ticketTotal, setTicketTotal] = useState(0)
  const [ticketNum, setTicketNum] = useState(1)
  const [ticketTotalPence, setTicketTotalPence] = useState(0)
  const [previousBooking, setPreviousBooking] = useState(false)
  const ticketNumRef = useRef(1)
  let currentUser = useCurrentUser()

  useEffect(() => {
    callApi(`/ticket-giveaways/${giveawayTitle}`, "GET")
      .then(res => {
        // console.log("res", res)
        setGiveawayData(res)
        setTicketTotal((res.ticket_price_in_pence / 100).toFixed(2))
        setTicketTotalPence(res.ticket_price_in_pence / 1) //to coerce as int

        //have they already got tickets
        const giveawayId = res.id
        const userId = currentUser.id
        callApi(
          `/orders?ticket_giveaway=${giveawayId}&user=${userId}`,
          "GET"
        ).then(booked => {
          // console.log("booked", booked)
          if (booked.length > 0) {
            // console.log("you have booked previously")
            setPreviousBooking(true)
          }
        })
      })
      .catch(err => {
        setGiveawayData("error")
      })
  }, [])

  useEffect(() => {
    ticketNumRef.current = ticketNum
    console.log("ticketNumRef Use Effect ran", ticketNumRef)
  }, [ticketNum])

  const handleSubmit = e => {
    e.preventDefault()
    // console.log("ticketTotal", ticketTotal)
    // console.log("ticketTotalPence", ticketTotalPence)

    setBuyState(true)
  }

  const handleTicketChange = event => {
    setTicketNum(event.target.value)
    console.log("dropdownEvent", event)
    //console.log("ticketChange", event.target.value)
    const orderTotal = giveawayData.ticket_price_in_pence * event.target.value
    setTicketTotal((orderTotal / 100).toFixed(2))
    setTicketTotalPence(orderTotal)
  }

  const handleFormClose = () => {
    setBuyState(false)
    setTicketTotal("5.00")
  }

  if (giveawayData === "loading") {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  } else if (giveawayData === "error") {
    navigate("/app?error=no-ga")
    return null
  } else {
    // console.log("giveawayData", giveawayData)
    const priceInPounds = (giveawayData.ticket_price_in_pence / 100).toFixed(2)
    //.toLocaleString('en-GB', {style: 'currency', currency: 'GBP'})
    //build select
    const selectOptions = num => {
      let options = []
      for (let i = 0; i < num; i++) {
        options.push(
          <option key={`option${i}`} value={i + 1}>
            {i + 1}
          </option>
        )
      }
      return options
    }
    //   const openingTotal = giveawayData.ticket_price_in_pence
    //     const openingTotalInPounds = openingTotal
    //     setTicketTotal(openingTotalInPounds)
    //     console.log('openingTotal', openingTotal)
    //sort image
    let giveawayImage = giveawayData.image.img_src?.formats?.medium
      ? giveawayData.image.img_src?.formats?.medium.url
      : giveawayData.image.img_src.url
    return (
      <Box className="giveaway" sx={styles}>
        <Box className="giveawayHeader">
          <Box className="giveawayDetails">
            <h1>Ticket Giveaway - {giveawayData.title}</h1>
            <Box className="giveawayInfo">
              <SanitizedHTMLBodyCopy content={giveawayData.offer} />
            </Box>
          </Box>
          <Box className="giveawayImage">
            <img
              src={`${API_URL}${giveawayImage}`}
              alt={giveawayData.image.img_alt}
            />
          </Box>
        </Box>

        <Box className="giveawayText">
          <SanitizedHTMLBodyCopy content={giveawayData.description} />
        </Box>
        <Box className="giveawayForm">
          <Flex className="orderDetails">
            <p>
              <span>Ticket Price:</span> <strong>£{priceInPounds}</strong>
            </p>
            <p>
              <span>Maximum Tickets Per Member:</span>{" "}
              <strong>{giveawayData.maximum_tickets_allowed}</strong>
            </p>
          </Flex>
          <div className="orderTotal">
            <p>
              Order Total: £
              {ticketTotal
                ? ticketTotal
                : (giveawayData.ticket_price_in_pence / 100).toFixed(2)}
            </p>
          </div>
          {previousBooking ? (
            <Message className="message">
              You have already booked tickets
            </Message>
          ) : (
            <>
              {buyState ? (
                <Elements stripe={stripe}>
                  <GiveawayForm
                    onChange={handleFormClose}
                    ticketTotal={ticketTotal}
                    ticketTotalPence={ticketTotalPence}
                    giveawayData={giveawayData}
                    ticketNum={ticketNumRef.current}
                    giveawayImage={`${API_URL}${giveawayImage}`}
                    pageURL={pageURL}
                  />
                </Elements>
              ) : (
                <form>
                  <div>
                    <Label>No. of Tickets</Label>
                    <Select defaultValue="1" onChange={handleTicketChange}>
                      {selectOptions(giveawayData.maximum_tickets_allowed)}
                    </Select>
                  </div>

                  <Button onClick={handleSubmit}>Buy Tickets</Button>
                </form>
              )}
            </>
          )}
        </Box>
        {/* <div>
                    <h1>Current Giveaways</h1>
                    <GiveawaysGrid />
                    </div> */}
      </Box>
    )
  }
}

export default Giveaway

const styles = {
  "&.giveaway": {
    mb: 5,

    ".giveawayHeader": {
      display: "flex",
      flexWrap: "wrap",
      flexDirecton: ["column", "row"],
      ".giveawayImage": {
        width: ["100%", null, "40%"],
        maxHeight: ["300px", "350px", "400px"],
        pr: 3,
        img: {
          maxWidth: ["100%", "100%", "90%"],
          maxHeight: "100%",
        },
        order: [1, 1, 2],
      },
      ".giveawayDetails": {
        order: [2, 2, 1],
        width: ["100%", null, "60%"],
        pr: 3,
        h1: {
          ontSize: 6,
          fontWeight: 700,
          mb: 0,
        },
        h2: { color: "muted", mt: 1 },
        ".giveawayInfo": {
          display: "flex",
          div: {
            mr: 4,
          },
          h4: {
            color: "muted",
            mb: 0,
            fontWeight: "normal",
          },
          p: {
            color: "muted",
            mt: 2,
            fontWeight: "normal",
            fontSize: 3,
          },
        },
      },
    },
    ".giveawayText": {
      fontSize: 3,
      color: "muted",
      pl: 2,
    },
    ".book-btn:disabled": {
      opacity: 0.3,
    },
    ".message": {
      borderLeftColor: "accent",
      bg: "#ffb6b6",
      display: "inline-block",
    },
  },
  ".giveawayForm": {
    borderRadius: "4px",
    padding: 3,
    border: theme => `2px solid ${theme.colors.accent}`,
    width: ["100%", "75%", "50%"],
    button: {
      mt: 2,
    },
    ".orderDetails > p": {
      mr: 3,
      fontSize: 3,
      span: {
        color: "muted",
      },
    },
    ".orderTotal": {
      fontSize: 4,
      p: {
        mt: 1,
      },
    },
  },
  ".message": {
    borderLeftColor: "accent",
    bg: "#ffb6b6",
    display: "inline-block",
  },
}
