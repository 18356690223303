/** @jsx jsx */
import { Box, Grid, jsx, Flex, Input, Label, Select, Checkbox } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useRef, useState, useEffect } from "react"
import { useDispatchCurrentUser, useCurrentUser } from "../CurrentUser"
import { callApi, callApiNoAuth, getAge } from "../../utils"
import { navigate, Link } from "gatsby"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { string } from "prop-types"
import Tooltip from "../Tooltip"
import { FaQuestionCircle } from "react-icons/fa"

const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const OnboardingAppPage = () => {
  const [errorMsg, setErrorMsg] = useState(null)
  const [referralOther, setReferralOther] = useState(false)

  const dispatch = useDispatchCurrentUser()
  let currentUser = useCurrentUser()

  const handleReferralChange = e => {
    //console.log("referralChanged", e.target.value)
    if (e.target.value === "Other") {
      setReferralOther(true)
    } else {
      setReferralOther(false)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    contactNumber: Yup.string().required("Contact Number is required"),
    cvURL: Yup.string().url(),
    //dateOfBirth: Yup.string().required("Date of Birth is required"),
    dateOfBirth: Yup.date("Please enter a valid date")
      .required("DOB is required")
      .test("minAge", "You must be 16 or older", function (dateOfBirth) {
        const cutoffMin = new Date()
        cutoffMin.setFullYear(cutoffMin.getFullYear() - 16)
        return dateOfBirth <= cutoffMin
      })
      .test("maxAge", "You must 79 or younger", function (dateOfBirth) {
        const cutoffMax = new Date()
        cutoffMax.setFullYear(cutoffMax.getFullYear() - 80)
        return dateOfBirth >= cutoffMax
      }),
    town: Yup.string().required("Town is required"),
    postCode: Yup.string().matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, {
      message: "Please enter a valid UK postcode",
    }),
    // photo:  Yup.mixed().required("Profile picture is required")
    photo: Yup.mixed().test(
      "required",
      "You need to upload a profile picture",
      value => {
        if (value.length < 1) {
          return false
        } else {
          return true
        }
      }
    ),
    ethnicOrigins: Yup.string().required(
      "Please select an ethnic origin or select Prefer not to say"
    ),
    gender: Yup.string().required(
      "Please select a gender option or select Prefer not to say"
    ),
    areaOfInterest: Yup.string().required("Please select an Area of Interest"),
    referralType: Yup.string().required(
      "Please select an option to let us know how you heard about us"
    ),
    referralTypeOther: Yup.string(),
    confirmCheck: Yup.boolean().oneOf([true], "This field must be checked"),
  })
  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const formSubmit = async validData => {
    // console.log("form data", validData)

    //e.preventDefault()
    //make sure date type is correct
    let dateISO = new Date(validData.dateOfBirth).toISOString().split("T")[0]
    //work out age
    const userAge = getAge(dateISO)
    let userStatus
    // console.log("userAge", userAge)
    //temp for pandemic users that lost a year
    if (currentUser.userStatus === "Migrated_Extra_Year") {
      if (userAge >= 32) {
        // console.log("you need a subscription baby")
        userStatus = "Onboarded"
      } else {
        userStatus = "Subscribed_Pandemic_Extra_Year"
      }
    } else {
      if (userAge >= 31) {
        // console.log("you need a subscription baby")
        userStatus = "Onboarded"
      } else {
        userStatus = "Subscribed"
      }
    }

    // console.log("dateISO", dateISO)
    try {
      const response = await callApi(`/users/${currentUser.id}`, "PUT", {
        id: currentUser.id,
        firstName: validData.firstName,
        lastName: validData.lastName,
        contactNumber: validData.contactNumber,
        dateOfBirth: dateISO,
        cvUrl: validData.cvURL,
        userStatus,
        //new fields
        town: validData.town,
        postCode: validData.postCode,
        ethnicOrigin: validData.ethnicOrigins,
        gender: validData.gender,
        areaOfInterest: validData.areaOfInterest,
        referralType: validData.referralType,
        referralTypeOther: validData.referralTypeOther,
      })

      //upload file
      const fileInput = document.querySelector("#photo")
      const formData = new FormData()
      formData.append("files", fileInput.files[0])
      formData.append("refId", currentUser.id)
      formData.append("ref", "user")
      formData.append("field", "photo")
      formData.append("source", "users-permissions")

      const uploadResponse = await fetch(`${API_URL}/upload`, {
        method: "POST",
        body: formData,
        credentials: "include",
      })
      const data = await uploadResponse.json()
      // console.log("data response", data)
      //change userStatus in context
      currentUser.userStatus = userStatus
      currentUser.firstName = validData.firstName
      currentUser.lastName = validData.lastName
      currentUser.contactNumber = validData.contactNumber
      currentUser.dateOfBirth = validData.dateOfBirth
      currentUser.cvUrl = validData.cvURL
      currentUser.town = validData.town
      currentUser.postCode = validData.postCode
      currentUser.gender = validData.gender
      currentUser = { ...currentUser, photo: data[0] } //file response is an array with 1 thing in it.
      dispatch({
        type: "UPDATE",
        response: {
          ...currentUser,
          photo: data[0],
        },
      })
      // console.log("currentUser with Photo: ", currentUser)
      //need to sort error responses from fetches
      if (userStatus === "Onboarded") {
        toast.success("😀 Account updated successfully")
        navigate("/app/subscribe")
      } else {
        toast.success("😀 Membership successfully created")
        navigate("/app")
      }
    } catch (error) {
      // setErrorMsg(err)
      toast.error(`🧨 ${error}`, {
        autoClose: false,
      })
    }
  }
  // console.log("errors", errors)

  return (
    <Box sx={styles} className="onboardingPage">
      <h1>Onboarding</h1>
      <p>
        We need to complete your account registration and user profile before
        you have full access to your dashboard.
      </p>
      <p>
        Thank you for choosing to register with Masterclass. We will process
        your data in accordance with the Data Protection Act 1998 and the
        General Data Protection Regulation (GDPR). Masterclass will use your
        information for administration, marketing and charitable purposes, and
        will not share your personal details with any third parties without your
        consent.
      </p>
      <p>For more information please refer to our terms and privacy policy.</p>
      <p>
        Our charitable objectives are to advance the education of young people
        aged 16 – 30 by promoting the study of drama and in particular the art
        and craft of theatre.
      </p>
      <Box>
        <form
          onSubmit={handleSubmit(formSubmit)}
          onReset={reset}
          encType="multipart/form-data"
        >
          <fieldset id="onboarding" sx={{ border: "none", py: 3 }}>
            <p>Please fill out the form to complete your user profile.</p>
            <div>
              <Label htmlFor="firstName">First Name</Label>
              <Input
                {...register("firstName")}
                type="text"
                name="firstName"
                id="firstName"
                defaultValue={currentUser.firstName}
              />
              {errors.firstName && (
                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                {...register("lastName")}
                type="text"
                name="lastName"
                id="lastName"
                defaultValue={currentUser.lastName}
              />
              {errors.lastName && (
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="contactNumber">Contact Number</Label>
              <Input
                {...register("contactNumber")}
                type="number"
                name="contactNumber"
                id="contactNumber"
              />
              {errors.contactNumber && (
                <div className="invalid-feedback">
                  {errors.contactNumber?.message}
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="town">Town</Label>
              <Input {...register("town")} type="text" name="town" id="town" />
              {errors.town && (
                <div className="invalid-feedback">{errors.town?.message}</div>
              )}
            </div>
            <div>
              <Label htmlFor="postCode">Post Code</Label>
              <Input
                {...register("postCode")}
                type="text"
                name="postCode"
                id="postCode"
              />
              {errors.postCode && (
                <div className="invalid-feedback">
                  {errors.postCode?.message}
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="cvURL">
                <span>Link to CV</span>
              </Label>

              <Input
                {...register("cvURL")}
                type="url"
                name="cvURL"
                id="cvURL"
              />
              {errors.cvURL && (
                <div className="invalid-feedback">{errors.cvURL?.message}</div>
              )}
            </div>
            <div>
              <Label htmlFor="dateOfBirth">Date of Birth</Label>
              <Input
                {...register("dateOfBirth")}
                type="date"
                name="dateOfBirth"
                id="dateOfBirth"
                defaultValue={currentUser.dateOfBirth}
              />
              {errors.dateOfBirth && (
                <div className="invalid-feedback">
                  {errors.dateOfBirth?.message}
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="photo">
                <span>Profile Image</span>
                <Tooltip
                  content="This picture is not a public profile image and is only used by the Masterclass team for admin purposes and choosing which members can attend limited space events"
                  direction="right"
                >
                  <FaQuestionCircle />
                </Tooltip>
              </Label>
              <Input
                {...register("photo")}
                type="file"
                name="photo"
                id="photo"
              />
              {errors.photo && (
                <div className="invalid-feedback">{errors.photo?.message}</div>
              )}
            </div>
            <div>
              <Label htmlFor="ethnicOrigins">Ethnic Origin</Label>
              <Select
                {...register("ethnicOrigins")}
                name="ethnicOrigins"
                id="ethnicOrigins"
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                <option value="Asian_or_British_Asian_Bangladeshi">
                  Asian or British Asian (Bangladeshi)
                </option>
                <option value="Asian_or_British_Asian_Indian">
                  Asian or British Asian (Indian)
                </option>
                <option value="Asian_or_British_Asian_Pakistani">
                  Asian or British Asian (Pakistani)
                </option>
                <option value="Other_Asian_Background">
                  Other Asian Background
                </option>
                <option value="Black_or_British_Black_African">
                  Black or British Black (African)
                </option>
                <option value="Black_or_British_Black_Caribbean">
                  Black or British Black (Caribbean)
                </option>
                <option value="Other_Black_Background">
                  Other Black Background
                </option>
                <option value="Chinese">Chinese</option>
                <option value="Mixed_White_and_Asian">
                  Mixed (White and Asian)
                </option>
                <option value="Mixed_White_and_Black_African">
                  Mixed (White and Black African)
                </option>
                <option value="Mixed_White_and_Black_Caribbean">
                  Mixed (White and Black Caribbean)
                </option>
                <option value="Other_Mixed_Background">
                  Other Mixed Background
                </option>
                <option value="White_British">White British</option>
                <option value="White_Irish">White Irish</option>
                <option value="Other_White_Background">
                  Other White Background
                </option>
                <option value="Other_Ethnic_Background">
                  Other Ethnic Background
                </option>
                <option value="Prefer_not_to_say">Prefer not to say</option>
              </Select>
              {errors.ethnicOrigins && (
                <div className="invalid-feedback">
                  {errors.ethnicOrigins?.message}
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="gender">Gender</Label>
              <Select {...register("gender")} name="gender" id="gender">
                <option value="" disabled selected>
                  Select your option
                </option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Non_binary">Non Binary</option>
                <option value="Prefer_not_to_say">Prefer not to say</option>
                <option value="Other">Other</option>
              </Select>
              {errors.gender && (
                <div className="invalid-feedback">{errors.gender?.message}</div>
              )}
            </div>
            <div>
              <Label htmlFor="areaOfInterest">Area of Interest</Label>
              <Select
                {...register("areaOfInterest")}
                name="areaOfInterest"
                id="areaOfInterest"
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                <option value="Acting">Acting</option>
                <option value="Directing">Directing</option>
                <option value="Journalism">Journalism</option>
                <option value="Lighting_Design">Lighting Design</option>
                <option value="Marketing_PR">Marketing / PR</option>
                <option value="Music_Musical_Direction">
                  Music /Musical Direction
                </option>
                <option value="Producing">Producing</option>
                <option value="Scenic_Arts_Design">Scenic Arts / Design</option>
                <option value="Sound_Design">Sound Design</option>
                <option value="Stage_Management">Stage Management</option>
                <option value="Technical_Theatre">Technical Theatre</option>
                <option value="Writing">Writing</option>
              </Select>
              {errors.areaOfInterest && (
                <div className="invalid-feedback">
                  {errors.areaOfInterest?.message}
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="referralType">How Did You Hear About Us?</Label>
              <Select
                {...register("referralType")}
                name="referralType"
                id="referralType"
                onChange={handleReferralChange}
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                <option value="Flyer_Postcard">Flyer / Postcard</option>
                <option value="From_a_friend">From a friend</option>
                <option value="From_a_teacher">From a teacher</option>
                <option value="Google">A Google Search</option>
                <option value="Facebook_group">A Facebook Group</option>
                <option value="Masterclass_Facebook_Page">
                  Masterclass Facebook Page
                </option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
                <option value="TheatreCraft">TheatreCraft</option>
                <option value="Theatre_Royal_Haymarket">
                  Theatre Royal Haymarket
                </option>
                <option value="Other">Other</option>
              </Select>
              {errors.referralType && (
                <div className="invalid-feedback">
                  {errors.referralType?.message}
                </div>
              )}
            </div>
            <div>
              {referralOther && (
                <Label htmlFor="referralTypeOther">
                  Please Specify How You Heard About Us...
                </Label>
              )}

              <Input
                {...register("referralTypeOther")}
                type={referralOther ? "text" : "hidden"}
                name="referralTypeOther"
                id="referralTypeOther"
              />
            </div>
            <div>
              {/* <Checkbox
                defaultChecked={false}
                {...register("confirmCheck")}
                name="confirmCheck"
                id="confirmCheck"
              /> */}
              <Flex className="confirmCheck">
                <input
                  {...register("confirmCheck")}
                  type="checkbox"
                  name="confirmCheck"
                  id="confirmCheck"
                />
                <Label htmlFor="confirmCheck">
                  <p>
                    By checking this box I agree for Masterclass to store my
                    data as explained above and set out in our{" "}
                    <a
                      href="https://masterclass.org.uk/contact-us/privacy-policy"
                      target="_blank"
                    >
                      terms and conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://masterclass.org.uk/contact-us/privacy-policy"
                      target="_blank"
                    >
                      privacy policy
                    </a>
                  </p>
                </Label>
              </Flex>
              {errors.confirmCheck && (
                <div className="invalid-feedback">
                  {errors.confirmCheck?.message}
                </div>
              )}
            </div>
            <div>
              <Input type="submit" value="Submit" />
            </div>
            <div>{errorMsg && <p className="error">{errorMsg}</p>}</div>
          </fieldset>
        </form>
      </Box>
    </Box>
  )
}

export default OnboardingAppPage

const styles = {
  "&.onboardingPage": {
    fieldset: {
      background: "white",
      padding: 4,
    },

    "& fieldset > div": {
      marginBottom: 3,
    },
    "& input[type=submit]": {
      bg: "accent",
      color: "white",
      cursor: "pointer",
      transition: "background 0.3s ease-in-out",
      "&:hover": {
        background: darken("accent", 0.1),
      },
    },
    "& input[type=text]:focus-visible": {
      outline: "red",
    },
    "& .confirmCheck": {
      p: {
        mt: 0,
      },
    },
    a: {
      color: "accent",
    },
    "& .invalid-feedback": {
      color: "red",
    },
    "& .error": {
      color: "red",
    },
    "& .Tooltip-Wrapper": {
      ml: 2,
      "&:hover": {
        cursor: "pointer",
      },
      svg: {
        verticalAlign: "-0.16em",
      },
    },
  },
}
