/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import React from "react"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const Welcome = ({ currentUser }) => {
  const today = new Date()
  const hours = today.getHours()
  let greeting
  if (hours >= 0 && hours < 12) {
    greeting = "Good Morning"
  } else if (hours >= 12 && hours <= 17) {
    greeting = "Good Afternoon"
  } else {
    greeting = "Good Evening"
  }
  return (
    <div sx={styles}>
      <Box className="welcomeTitle">
        <h1>{`${greeting} ${currentUser.firstName},`}</h1>
      </Box>
      {currentUser?.photo?.url && (
        <img
          src={`${API_URL}${currentUser?.photo?.url}`}
          alt={`${currentUser.firstName} ${currentUser.lastName} Profile Pic`}
        />
      )}
    </div>
  )
}

export default Welcome

const styles = {
  display: "flex",
  "& .welcomeTitle": {
    marginLeft: 0,
    marginRight: 0,
    background:
      "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
    transform: "rotate(-3deg) skew(-3deg) !important",
    py: 3,
    px: 3,
    position: "relative",
    //mt: "-100px",
    mb: 3,
    display: "inline-block",
    "& h1": {
      color: "white",
      transform: "rotate(3deg) skew(3deg) !important",
    },
  },
  img: {
    mt: "-12px",
    ml: -1,
    zIndex: 5,
  },
}
