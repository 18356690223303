/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Spinner, jsx, Box, Select, Button, Grid } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import { Link, navigate } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import { callApi, readableDate } from "../../utils"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const YourEvents = ({ currentUser }) => {
  const [eventData, setEventData] = useState("loading")
  const [successMsg, setSuccessMsg] = useState(null)

  ///attendees?users_id=${currentUser.id}&status=accepted
  //contains accepted
  ///attendees?users_id=${currentUser.id}&_where[status_contains]=accepted

  useEffect(() => {
    callApi(
      `/attendees?users_id=${currentUser.id}&_where[status_contains]=accepted`,
      "GET"
    )
      .then(res => {
        // console.log("res", res)
        if (res.length < 1) {
          setEventData("empty")
        } else {
          setEventData(res)
        }
      })
      .catch(err => {
        setEventData("error")
      })
  }, [])

  const handleCancel = async (id, event_name) => {
    // console.log("cancel clicked")
    const updateAttendee = await callApi(`/attendees/${id}`, "PUT", {
      status: "cancelled",
      user: currentUser.id,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      event_name: event_name,
      email: currentUser.email,
    }).then(res => {
      // console.log("cancelRes", res)
      setSuccessMsg("Your attendence at this event has been cancelled.")
      navigate("/app")
    })
  }

  //return
  if (eventData === "loading") {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  } else if (eventData === "error") {
    return (
      <>
        <p>There is an error</p>
      </>
    )
  } else if (eventData === "empty") {
    return (
      <>
        <p>There are currently no events to display.</p>
      </>
    )
  } else {
    //split into future and past events
    const today = new Date()
    let futureEvents = new Array()
    let pastEvents = new Array()
    eventData.map(event => {
      const eventDate = new Date(event.event_id.Date)
      if (eventDate > today) {
        futureEvents.push(event)
      } else {
        pastEvents.push(event)
      }
    })
    return (
      <>
        <h1>Your Upcoming Events</h1>
        <Grid
          className="futureEvents"
          sx={futureStyles}
          gap={2}
          columns={[1, 2, 4]}
          autoFill
        >
          {futureEvents.map(event => {
            return (
              <div key={`event-${event.id}`}>
                <Link
                  to={`/app/event/${event.event_id.slug}`}
                  className="event"
                >
                  <div className="eventImage">
                    <img
                      src={`${API_URL}${event.event_id.Img.url}`}
                      alt={event.event_id.Img_alt}
                    />
                    <div className="arrow">
                      <FaArrowRight />
                    </div>
                  </div>
                  <div className="eventDetails">
                    <h1>{event.event_name}</h1>
                    <h2>{readableDate(event.event_id.Date)}</h2>
                    <h3>{event.event_id.Type}</h3>
                    {event.event_id.Time && (
                      <ul>
                        <li>{event.event_id.Time}</li>
                      </ul>
                    )}
                  </div>
                </Link>
                <div className="eventButtons">
                  <Button
                    onClick={() => handleCancel(event.id, event.event_name)}
                  >
                    Cancel
                  </Button>
                  <Link to={`/app/event/${event.event_id.slug}`}>
                    Go to Event
                  </Link>
                </div>
              </div>
            )
          })}
        </Grid>
        <h1>Past Events</h1>
        <Box className="pastEvents" sx={pastStyles}>
          {pastEvents.map(event => {
            return (
              <div key={`event-${event.id}`} className="event">
                <div className="eventImage">
                  <img
                    src={`${API_URL}${event.event_id.Img.url}`}
                    alt={event.event_id.Img_alt}
                  />
                </div>
                <div className="eventDetails">
                  <div>
                    <h2>{event.event_name}</h2>
                    <h3>{event.event_id.Type}</h3>
                  </div>
                  <div>{readableDate(event.event_id.Date)}</div>
                  <div sx={{ "& a": { mr: 2 } }}>
                    <Link to={`/event/${event.event_id.slug}`}>
                      Event Details
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </Box>
      </>
    )
  }
}
export default YourEvents

const futureStyles = {
  mb: 4,
  "& .event": {
    textDecoration: "none",
    "& .eventImage": {
      position: "relative",
      overflow: "hidden",
      height: "400px",
      img: {
        objectFit: "cover",
        maxWidth: "100%",
        height: "400px",
        transition: "all .3s ease",
      },
    },
    "& .arrow": {
      width: "50px",
      height: "50px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 4,
      bottom: "0",
      transition: "background .3s ease",
      "& svg": {
        position: "relative",
        color: "accent",
        fontSize: 5,
      },
    },
    "& .eventDetails": {
      transition: "background .3s ease",
      "& h1": {
        color: "black",
        fontSize: 3,
        mt: 1,
        mb: 0,
        fontWeight: "700",
      },
      "& h2": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "muted",
        fontWeight: "400",
      },
      "& h3": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "accent",
        fontWeight: "400",
      },
      "& ul": {
        pl: 0,
        mt: 0,
        listStyle: "none",
        color: "muted",
      },
      "& li::before": {
        content: '"• "',
        color: "accent",
      },
    },
    //event hover
    "&:hover": {
      "& .eventImage": {
        img: {
          transform: "scale(1.2)",
        },
        "& .arrow": {
          background: "black",
        },
      },
    },
  },
  ".eventButtons": {
    display: "flex",
    alignItems: "center",
    button: {
      mr: 3,
      cursor: "pointer",
    },
    a: {
      textDecoration: "none",
      px: 3,
      py: 2,
      bg: "accent",
      color: "white",
      cursor: "pointer",
      borderRadius: "4px",
      transition: "background 0.3s ease-in-out",
      "&:hover": {
        background: darken("accent", 0.1),
      },
    },
  },
}

const pastStyles = {
  mb: 4,
  ".event": {
    display: "flex",
    ".eventImage": {
      width: ["40%", null, "20%"],
      mr: 4,
      img: {
        maxWidth: "100%",
      },
    },
    ".eventDetails": {
      display: "flex",
      alignItems: "center",
      h2: {
        mb: 0,
      },
      h3: {
        mt: 0,
        color: "muted",
      },
      div: {
        mr: 4,
      },
      a: {
        textDecoration: "none",
        px: 3,
        py: 2,
        bg: "accent",
        color: "white",
        cursor: "pointer",
        borderRadius: "4px",
        transition: "background 0.3s ease-in-out",
        "&:hover": {
          background: darken("accent", 0.1),
        },
      },
    },
  },
}
