import React from "react"
import { ToastContainer, toast } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

const Toast = () => {
  // const notify = (type, message) => {
  //   switch (type) {
  //     case "error":
  //       toast.error(`Error: ${message}!`, {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //         theme: "dark",
  //       })
  //       break
  //     case "success":
  //       toast.success(`Success: ${message}!`, {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //         theme: "dark",
  //       })
  //       break
  //   }
  // }

  return (
    <div>
      {/* <button onClick={(e) => notify('error', 'custom message goes here...')}>Notify !</button> */}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        theme="dark"
        toastStyle={{ backgroundColor: "crimson" }}
      />
    </div>
  )
}

export default Toast
