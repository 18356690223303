/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Spinner, jsx, Box, Select, Button, Label } from "theme-ui"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import { callApi } from "../../../utils"
import { useCurrentUser, useDispatchCurrentUser } from "../../CurrentUser"
import { navigate, Link } from "gatsby"

const GiveawayForm = props => {
  const stripe = useStripe()
  const elements = useElements()

  const [token, setToken] = useState(null)
  const [total, setTotal] = useState("loading")
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)

  let currentUser = useCurrentUser()

  useEffect(() => {
    const loadToken = async () => {
      setLoading(true)
      console.log("ticketNum in form", props.ticketNum)
      const response = await callApi(`/ticket-giveaways/payment`, "POST", {
        userId: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        ticketTotal: props.ticketTotalPence,
        giveawayId: props.giveawayData.id,
        giveawayTitle: props.giveawayData.title,
        ticketNum: props.ticketNum,
      })

      console.log("response", response)
      setToken(response.client_secret)
      setTotal(response.amount)
      setLoading(false)
      // console.log("currentUser", currentUser)
      // console.log("giveawayTitle form", props.giveawayData.title)
    }

    loadToken().catch(error => {
      //console.log(error)
      setErrMsg(error)
      setLoading(false)
    })
  }, [])

  const handleFormChange = () => {
    //calls in parent for form to be closed

    props.onChange()
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    setSuccessMsg("")
    setErrMsg("")
    if (!stripe || !elements) {
      return
    }
    //double check tickets are still available
    const giveawayCheck = await callApi(
      `/ticket-giveaways/${props.giveawayData.slug}`,
      "GET",
      {}
    )

    if (giveawayCheck.tickets_available < props.ticketNum) {
      setLoading(false)
      setErrMsg("Sorry, there aren't enough tickets left!")
      return
    } else {
      //make payment
      // console.log("token", token)
      const result = await stripe.confirmCardPayment(token, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      if (result.error) {
        // console.log("handleSubmit error", result)
        setLoading(false)
        setErrMsg("There was a problem with your payment.")

        return
      }
      // console.log("handleSubmit success", result)
      setLoading(false)
      setSuccessMsg(
        "Your payment was successful, redirecting you to user dashboard ... "
      )
      //update giveaway to take away tickets
      const updateTicketNum = giveawayCheck.tickets_available - props.ticketNum
      const updateGiveawayResponse = await callApi(
        `/ticket-giveaways/${props.giveawayData.id}`,
        "PUT",
        {
          tickets_available: updateTicketNum,
        }
      )
      // console.log("updateGiveawayResponse", updateGiveawayResponse)

      //add order
      const orderResponse = await callApi(`/orders`, "POST", {
        user: currentUser.id,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        description: `${props.giveawayData.title} Giveaway`,
        payment_intent: result.paymentIntent.id,
        total_in_pence: result.paymentIntent.amount,
        type: `ticket_giveaway`,
        no_of_tickets: props.ticketNum,
        ticket_giveaway: props.giveawayData.id,
        status: "success",
        email: currentUser.email,
        //receipt: result.
        image: props.giveawayImage,
        totalForEmail: props.ticketTotal,
        pageURL: props.pageURL,
      })
      // console.log("orderResponse", orderResponse)

      navigate("/app")
    } //if tickets left
  }

  return (
    <div sx={styles}>
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
              },
            },
          }}
          disabled={loading}
        />
        <Button onClick={handleFormChange}>Cancel</Button>
        <Button type="submit" disabled={!stripe || loading}>
          Complete Purchase
        </Button>
      </form>
      {loading && <Spinner title="loading" className="spinner" />}

      <div>
        {errMsg && <p className="error">{errMsg}</p>}
        {successMsg && (
          <p>
            {successMsg}{" "}
            <span aria-hidden="true" role="img">
              😊
            </span>
          </p>
        )}
      </div>
    </div>
  )
}

export default GiveawayForm

const styles = {
  "& button": {
    mt: 3,
    mr: 2,
    cursor: "pointer",
  },
  "& .StripeElement": {
    border: "1px black solid",
    padding: 3,
    borderRadius: "4px",
    mb: 2,
  },
  "& .error": {
    color: "red",
  },
  "& .spinner": {
    color: "red",
  },
}
