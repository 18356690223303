/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import React from "react"
import GiveawaysGrid from "./GiveawaysGrid"
import OffersGrid from "./OffersGrid"
import EventsGrid from "./EventsGrid"
import { toast } from "react-toastify"

const Dashboard = () => (
  <Box sx={styles}>
    <div>
      <p className="lead">
        Welcome to your dashboard! Here, you can find information about future
        events, ticket offers, and £5 ticket giveaways. You can also view your
        booked events, and manage your upcoming ticket giveaway bookings.
      </p>
      <p className="lead">
        Any questions, drop us an email at{" "}
        <a href="mailto:info@masterclass.org.uk">info@masterclass.org.uk</a>
      </p>
    </div>
    <div className="theGrids">
      <div>
        <h2>Upcoming Events</h2>
        <EventsGrid />
      </div>
      <div>
        <h2>Current Ticket Giveaways</h2>
        <GiveawaysGrid />
      </div>
      <div>
        <h2>Current Ticket Offers</h2>
        <OffersGrid />
      </div>
    </div>
  </Box>
)

export default Dashboard

const styles = {
  h1: {
    background: "accent",
    color: "white",
  },
  "p.lead": {
    fontSize: 4,
    color: "muted",
    a: {
      color: "accent",
    },
  },
  "& .theGrids": {
    mb: 3,
  },
}
