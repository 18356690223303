/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Spinner, jsx, Grid } from "theme-ui"
import { FaArrowRight } from "react-icons/fa"
import { callApi } from "../../utils"
import { navigate, Link } from "gatsby"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const OffersGrid = ({ skip }) => {
  const [offers, setOffers] = useState("loading")
  const [errorState, setErrorState] = useState(undefined)

  useEffect(() => {
    let query
    let isMounted = true
    if (skip) {
      query = `/ticket-offers?_where[offer_status]=live&_where[slug_ne]=${skip}&_sort=updated_at:DESC`
    } else {
      query = `/ticket-offers?_where[offer_status]=live&_sort=updated_at:DESC`
    }
    callApi(query, "GET")
      .then(res => {
        if (isMounted) {
          // console.log("res length", res.length)
          if (res.length === 0) {
            setOffers("empty")
          } else {
            setOffers(res)
          }
        }
      })
      .catch(err => {
        setOffers("error")
        setErrorState(err)
      })
    return () => {
      isMounted = false
    }
  }, [skip])

  // console.log("offers", offers)
  // console.log("skip", skip)

  if (offers === "loading") {
    return (
      <>
        <p>Loading...</p>
        <Spinner sx={{ color: "red" }} />
      </>
    )
  } else if (offers === "empty") {
    return (
      <>
        <p>There are current no ticket offers</p>
      </>
    )
  } else if (offers === "error") {
    return (
      <>
        <p sx={{ color: "red" }}>{errorState} </p>
      </>
    )
  } else {
    return (
      <>
        <Grid gap={2} columns={[1, 2, 4]} sx={styles}>
          {offers.map(offer => {
            // if (skip === offer.slug){
            //     console.log(`skip ${offer.slug}`)
            //     return null
            // }
            return (
              <Link
                to={`/app/offer/${offer.slug}`}
                key={`offer-grid-${offer.id}`}
                className="offer"
              >
                <div className="offerImage">
                  <img
                    src={`${API_URL}${offer.image.img_src.url}`}
                    alt={offer.image.img_alt}
                  />
                  <div className="arrow">
                    <FaArrowRight />
                  </div>
                </div>
                <div className="offerDetails">
                  <h1>{offer.title}</h1>
                </div>
              </Link>
            )
          })}
        </Grid>
      </>
    )
  }
}

export default OffersGrid

const styles = {
  "& .offer": {
    textDecoration: "none",
    mb: 4,
    "& .offerImage": {
      position: "relative",
      overflow: "hidden",
      height: "400px",
      img: {
        objectFit: "cover",
        maxWidth: "100%",
        height: "400px",
        transition: "all .3s ease",
      },
    },
    "& .arrow": {
      width: "50px",
      height: "50px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 4,
      bottom: "0",
      transition: "background .3s ease",
      "& svg": {
        position: "relative",
        color: "accent",
        fontSize: 5,
      },
    },
    "& .offerDetails": {
      transition: "background .3s ease",
      "& h1": {
        color: "black",
        fontSize: 3,
        mt: 1,
        mb: 0,
        fontWeight: "700",
      },
      "& h2": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "muted",
        fontWeight: "400",
      },
      "& h3": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "accent",
        fontWeight: "400",
      },
      "& ul": {
        pl: 0,
        mt: 0,
        listStyle: "none",
        color: "muted",
      },
      "& li::before": {
        content: '"• "',
        color: "accent",
      },
    },
    //offer hover
    "&:hover": {
      "& .offerImage": {
        img: {
          transform: "scale(1.2)",
        },
        "& .arrow": {
          background: "black",
        },
      },
    },
  },
}
