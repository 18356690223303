/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import React from "react"
import { Link } from "gatsby"

export default () => (
  <ul
    sx={{
      pl: 0,
      mx: 3,
      listStyle: "none",
      display: "flex",
      flexWrap: "wrap",
      //mt: -5,
      li: {
        mb: 2,
        p: 2,
        pb: 1,
      },
      a: {
        textDecoration: "none",
        p: 1,
        fontSize: [4, 5],
        borderRadius: "4px",
        color: "muted",
        fontWeight: "bold",
        fontFamily: "heading",
        outline: theme => `1px grey solid`,
        ":hover": {
          //borderBottom: "2px solid red",
          outline: theme => `2px ${theme.colors.accent} solid`,
        },
      },
      "a.active": {
        outline: theme => `1px ${theme.colors.accent} solid`,
        bg: "accent",
        color: "white",
        borderRadius: "4px",
        ":hover": {
          borderBottom: "none",
        },
      },
    }}
  >
    <li>
      <Link to="/app" activeClassName="active" partiallyActive={false}>
        Dashboard
      </Link>
    </li>
    <li>
      <Link to="/app/account" activeClassName="active" partiallyActive={true}>
        Account
      </Link>
    </li>
    <li>
      <Link
        to="/app/your-events"
        activeClassName="active"
        partiallyActive={true}
      >
        Your Masterclasses
      </Link>
    </li>
    <li>
      <Link
        to="/app/your-orders"
        activeClassName="active"
        partiallyActive={true}
      >
        Your Giveaways
      </Link>
    </li>
  </ul>
)
