/** @jsx jsx */
import { jsx, Flex, Message } from "theme-ui"
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Navigation from "../components/app/Navigation"
import Dashboard from "../components/app/Dashboard"
import Account from "../components/app/Account"
import Onboarding from "../components/app/Onboarding"
import Subscribe from "../components/app/Subscribe"
import Giveaway from "../components/app/Giveaway"
import Offer from "../components/app/Offer"
import Event from "../components/app/Event"
import YourEvents from "../components/app/YourEvents"
import YourOrders from "../components/app/YourOrders"
import Toast from "../components/app/utils/toast"
import HeaderBlockColor from "../components/headerBlockColor"
import Welcome from "../components/app/Welcome"
//import useAuth from "../hooks/useAuth"
import { callApi } from "../utils"
import {
  useCurrentUser,
  useDispatchCurrentUser,
} from "../components/CurrentUser"

const App = ({ location }) => {
  // const { state, isAuthenticated } = useAuth()
  // const redirect = location.pathname.split("/").pop()
  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/login", { state: { redirect } })
  //   }
  // }, [isAuthenticated, redirect])
  let currentUser = useCurrentUser()
  const dispatch = useDispatchCurrentUser()
  const [welcome, setWelcome] = useState(false)
  const pageURL = location.href ? location.href : ""

  useEffect(() => {
    if (!currentUser.isAuthenticated) {
      navigate("/login")
    } else if (
      currentUser.userStatus === "New_Account" ||
      currentUser.userStatus === "Migrated" ||
      currentUser.userStatus === "Migrated_Extra_Year"
    ) {
      navigate("/app/onboarding")
    } else if (currentUser.userStatus === "Onboarded") {
      navigate("/app/subscribe")
    } else if (currentUser.userStatus === "Expired") {
      navigate("/app/subscribe?expired=true")
    } else {
      //can display page no redirect
      // console.log("location", location)
    }
    // console.log("currentUser", currentUser)
    //setwelcome message
    if (currentUser.userStatus !== "New_Account") {
      setWelcome(true)
    }
  }, [location.pathname])

  const handleLogout = async e => {
    e.preventDefault()
    const response = await callApi("/logout", "POST")
    // console.log("logout response", response)
    dispatch({ type: "LOGOUT" })
    navigate("/login")
  }

  return (
    <Layout>
      <HeaderBlockColor />
      <Toast />
      <div
        sx={{
          "& img": {
            height: "120px",
            width: "120px",
            borderRadius: "50%",
            objectFit: "cover",
          },
        }}
      >
        {welcome && <Welcome currentUser={currentUser} />}
        <div sx={{ display: "flex", flexWrap: "wrap" }}>
          <Navigation />
          <a
            onClick={handleLogout}
            href=""
            sx={{
              textDecoration: "none",
              p: 2,
              pb: 1,
              pt: [0, null, "12px"],
              fontSize: [4, 5],
              color: "muted",
              fontWeight: "bold",
              fontFamily: "heading",
              lineHeight: "24px",
              mt: [0, null, "24px"],
              mb: "24px",
              mx: [3],
              borderRadius: "4px",
              ":hover": {
                outline: theme => `2px ${theme.colors.accent} solid`,
              },
            }}
          >
            Logout
          </a>
        </div>
      </div>
      <Flex
        sx={{
          display: "block",
          flexDirection: "row-reverse",
          px: 4,
          justifyContent: "space-between",
        }}
      >
        <Router basepath="/app">
          <Account path="/account" currentUser={currentUser} />
          <YourEvents path="/your-events" currentUser={currentUser} />
          <YourOrders path="/your-orders" currentUser={currentUser} />
          <Onboarding path="/onboarding" />
          <Subscribe path="/subscribe" />
          <Giveaway path="/giveaway/:giveawayTitle" pageURL={pageURL} />
          <Offer
            path="/offer/:offerTitle"
            pageURL={pageURL}
            location={location}
          />
          <Event path="/event/:eventTitle" pageURL={pageURL} />
          <Dashboard default />
        </Router>
      </Flex>
    </Layout>
  )
}
export default App
