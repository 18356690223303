/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Spinner, jsx, Box, Select, Button, Label } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import { Link } from "gatsby"
import { callApi } from "../../utils"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const YourOrders = ({ currentUser }) => {
  const [orderData, setOrderData] = useState("loading")

  useEffect(() => {
    callApi(`/orders?user=${currentUser.id}&_sort=created_at:DESC`, "GET")
      .then(res => {
        // console.log("res", res)
        if (res.length < 1) {
          setOrderData("empty")
        } else {
          setOrderData(res)
        }
      })
      .catch(err => {
        setOrderData("error")
      })
  }, [])

  //return
  if (orderData === "loading") {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  } else if (orderData === "error") {
    return (
      <>
        <p>There is an error</p>
      </>
    )
  } else if (orderData === "empty") {
    return (
      <>
        <p>There are currently no orders to display.</p>
      </>
    )
  } else {
    return (
      <div className="orders" sx={styles}>
        {orderData.map(order => {
          //convert date
          const date = new Date(order.created_at)
          //date = date.toLocaleDateString()
          return (
            <div key={`order=${order.id}`} className="order">
              <div className="orderImage">
                <img
                  src={`${API_URL}${order.ticket_giveaway.image.img_src.url}`}
                  alt={order.ticket_giveaway.image.img_alt}
                />
              </div>
              <div className="orderDetails">
                <div className="orderDetailsHeader">
                  <h2>{order.description}</h2>
                </div>

                <div>
                  <p>Tickets: {order.no_of_tickets}</p>
                  <p>Order Total: £{(order.total_in_pence / 100).toFixed(2)}</p>
                </div>
                <div className="orderDate">
                  <p>Date: {date.toLocaleDateString()}</p>
                </div>
                <div sx={{ "& a": { mr: 2 } }}>
                  <a href={order.receipt} className="btn" target="_blank">
                    Receipt
                  </a>
                  <Link to={`/app/giveaway/${order.ticket_giveaway.slug}`}>
                    Go to Giveaway
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
export default YourOrders

const styles = {
  mb: 4,
  ".order": {
    display: "flex",
    flexWrap: "wrap",
    ".orderImage": {
      width: ["100%", null, "20%"],
      mr: [0, 4, 4],
      img: {
        maxWidth: "100%",
      },
    },
    ".orderDetails": {
      display: ["block", "flex", "flex"],
      alignItems: "center",
      h2: {
        mb: 0,
      },
      h3: {
        mt: 0,
        color: "muted",
      },
      div: {
        mr: 4,
        p: {
          mt: 0,
          mb: 0,
        },
      },
      a: {
        textDecoration: "none",
        px: 3,
        py: 2,
        bg: "accent",
        color: "white",
        cursor: "pointer",
        borderRadius: "4px",
        transition: "background 0.3s ease-in-out",
        "&:hover": {
          background: darken("accent", 0.1),
        },
      },
    },
    "& .orderDate": {
      mb: 3,
    },
  },
}
