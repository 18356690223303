/** @jsx jsx */
import React, { useState, useRef, useEffect } from "react"
import { Field, Button, Box, Flex, jsx, Select, Label, Spinner } from "theme-ui"
import { callApi } from "../../utils"
import { useDispatchCurrentUser, useCurrentUser } from "../CurrentUser"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Tooltip from "../Tooltip"
import { FaQuestionCircle } from "react-icons/fa"

const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const Account = ({ currentUser }) => {
  const [accountState, setAccountState] = useState(false)

  const [firstName, setFirstName] = useState(currentUser.firstName)
  const [lastName, setLastName] = useState(currentUser.lastName)
  const [contactNumber, setContactNumber] = useState(currentUser.contactNumber)
  const [town, setTown] = useState(currentUser.town)
  const [postCode, setPostCode] = useState(currentUser.postCode)
  const [gender, setGender] = useState(currentUser.gender)
  const [cvUrl, setCvUrl] = useState(currentUser.cvUrl)
  const [errorMsg, setErrorMsg] = useState(null)
  //this is to prevent double submits
  const [submitted, setSubmitted] = useState(false)
  const [picSubmitted, setPicSubmitted] = useState(false)

  //make date readable
  let dateOfBirth = new Date(currentUser.dateOfBirth)
  dateOfBirth = dateOfBirth.toDateString()
  //take off day name
  dateOfBirth = dateOfBirth.substr(dateOfBirth.indexOf(" ") + 1)

  const dispatch = useDispatchCurrentUser()

  //const profilePic = currentUser.photo?.url
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    contactNumber: Yup.string().required("Contact Number is required"),
    cvURL: Yup.string().url(),
    town: Yup.string().required("Town is required"),
    postCode: Yup.string().matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, {
      message: "Please enter a valid UK postcode",
    }),
    gender: Yup.string().required(
      "Please select a gender option or select Prefer not to say"
    ),
  })

  const photoValidationSchema = Yup.object().shape({
    photo: Yup.mixed().test(
      "required",
      "You need to upload a profile picture",
      value => {
        if (value.length < 1) {
          return false
        } else {
          return true
        }
      }
    ),
  })

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const formSubmit = async validData => {
    // console.log("validData", validData)
    // console.log("formsubmit fired")
    setSubmitted(true)
    try {
      const response = await callApi(`/users/${currentUser.id}`, "PUT", {
        id: currentUser.id,
        firstName: validData.firstName,
        lastName: validData.lastName,
        contactNumber: validData.contactNumber,
        cvUrl: validData.cvUrl,
        town: validData.town,
        postCode: validData.postCode,
        gender: validData.gender,
      })
      // console.log("response", response)
      //update context
      // console.log("currentUser", currentUser)
      currentUser = { ...currentUser, ...response }
      // console.log("updated currentUser", currentUser)
      //update with a dispatch (pass through reponse as it is effectively user)
      dispatch({ type: "UPDATE", response })
      //update state
      setFirstName(validData.firstName)
      setLastName(validData.lastName)
      setContactNumber(validData.contactNumber)
      setCvUrl(validData.cvUrl)
      setTown(validData.town)
      setPostCode(validData.postCode)
      setGender(validData.gender)
      //exit out of form state
      setAccountState(false)
      toast.success(`🌟 Account Details updated successfully`, {
        autoClose: true,
      })
      setSubmitted(false)
    } catch (err) {
      setErrorMsg(err)
      setSubmitted(false)
      // console.log("updateUser text err", err)
    }
  }

  //profile pic update
  const [picState, setPicState] = useState(false)
  const [profilePic, setProfilePic] = useState(currentUser.photo?.url)
  //cache busting hash after image to force update image
  const [picHash, setPicHash] = useState(Date.now())

  const handlePicSubmit = async event => {
    event.preventDefault()
    setPicSubmitted(true)
    // console.log("handlepic submit fired")
    const fileInput = document.querySelector("#photo")
    const formData = new FormData()
    formData.append("files", fileInput.files[0])
    formData.append("refId", currentUser.id)
    formData.append("ref", "user")
    formData.append("field", "photo")
    formData.append("source", "users-permissions")
    try {
      const uploadResponse = await fetch(`${API_URL}/upload`, {
        method: "POST",
        body: formData,
        credentials: "include",
      })
      const data = await uploadResponse.json()
      dispatch({
        type: "UPDATE",
        response: {
          ...currentUser,
          photo: data[0],
        },
      })
      // console.log("currentUser.photo.url", currentUser.photo?.url)
      setProfilePic(data[0].url)
      setPicState(false)
      setPicHash(Date.now())
      toast.success(`🤳 Profile Pic updated successfully`, {
        autoClose: true,
      })
      setPicSubmitted(false)
    } catch (err) {
      setErrorMsg(err)
      // console.log("updateUser photo err", err)
      setPicSubmitted(false)
    }
  }

  return (
    <Box className="accountPage" sx={styles}>
      <h1>Account Page</h1>
      <p className="lead">
        Please use this page to update your account details. If you notice an
        error in your details that is not editable please do get in contact.
      </p>
      <Flex className="accountPageContainer">
        <Box className="accountDetails">
          {accountState ? (
            <Box>
              <form onSubmit={handleSubmit(formSubmit)}>
                <Field
                  label="First Name"
                  name="firstName"
                  className="field"
                  defaultValue={firstName}
                  {...register("firstName")}
                />
                {errors.firstName && (
                  <div className="invalid-feedback">
                    {errors.firstName?.message}
                  </div>
                )}
                <Field
                  label="Last Name"
                  name="lastName"
                  className="field"
                  defaultValue={lastName}
                  {...register("lastName")}
                />
                {errors.lastName && (
                  <div className="invalid-feedback">
                    {errors.lastName?.message}
                  </div>
                )}
                <div>
                  <h3 className="fieldTitle">Email Address</h3>
                  <p>{currentUser.email}</p>
                </div>
                <div>
                  <h3 className="fieldTitle">Date Of Birth</h3>
                  <p>{dateOfBirth}</p>
                </div>
                <Field
                  label="Contact Number"
                  name="contactNumber"
                  className="field"
                  defaultValue={contactNumber}
                  {...register("contactNumber")}
                />
                {errors.contactNumber && (
                  <div className="invalid-feedback">
                    {errors.contactNumber?.message}
                  </div>
                )}
                <Field
                  label="CV Link"
                  name="cvUrl"
                  className="field"
                  defaultValue={cvUrl}
                  {...register("cvUrl")}
                />
                {errors.cvUrl && (
                  <div className="invalid-feedback">
                    {errors.cvUrl?.message}
                  </div>
                )}
                <Field
                  label="Town"
                  name="town"
                  className="field"
                  defaultValue={town}
                  {...register("town")}
                />
                {errors.town && (
                  <div className="invalid-feedback">{errors.town?.message}</div>
                )}

                <Field
                  label="Post Code"
                  name="postCode"
                  className="field"
                  defaultValue={postCode}
                  {...register("postCode")}
                />
                {errors.postCode && (
                  <div className="invalid-feedback">
                    {errors.postCode?.message}
                  </div>
                )}

                <Label htmlFor="gender">Gender</Label>
                <Select
                  {...register("gender")}
                  name="gender"
                  id="gender"
                  defaultValue={gender}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="Non_binary">Non Binary</option>
                  <option value="Prefer_not_to_say">Prefer not to say</option>
                  <option value="Other">Other</option>
                </Select>
                {errors.gender && (
                  <div className="invalid-feedback">
                    {errors.gender?.message}
                  </div>
                )}
                <div className="buttonRow">
                  <Button mr={2} onClick={() => setAccountState(false)}>
                    Cancel
                  </Button>
                  <Button mr={2} type="submit" disabled={submitted}>
                    Update
                  </Button>
                  {submitted && <Spinner title="loading" />}
                </div>
              </form>
            </Box>
          ) : (
            <Box>
              <div>
                <h3 className="fieldTitle">First Name</h3>
                <p>{firstName}</p>
              </div>
              <div>
                <h3 className="fieldTitle">Last Name</h3>
                <p>{lastName}</p>
              </div>
              <div>
                <h3 className="fieldTitle">Email Address</h3>
                <p>{currentUser.email}</p>
              </div>
              <div>
                <h3 className="fieldTitle">Date Of Birth</h3>
                <p>{dateOfBirth}</p>
              </div>
              <div>
                <h3 className="fieldTitle">Contact Number</h3>
                <p>{contactNumber}</p>
              </div>
              <div>
                <h3 className="fieldTitle">CV Link</h3>
                <a href={cvUrl}>{cvUrl}</a>
              </div>
              <div>
                <h3 className="fieldTitle">Town</h3>
                <p>{town}</p>
              </div>
              <div>
                <h3 className="fieldTitle">Post Code</h3>
                <p>{postCode}</p>
              </div>
              <div>
                <h3 className="fieldTitle">Gender</h3>
                <p>{gender}</p>
              </div>
              <Button mr={2} mt={2} onClick={() => setAccountState(true)}>
                Edit User Details
              </Button>
            </Box>
          )}
        </Box>
        <Box className="picDetails">
          <div className="profilePic">
            {profilePic && (
              <img
                src={`${API_URL}${profilePic}?${picHash}`}
                key={`profilePic-${picHash}`}
              />
            )}
          </div>
          {picState ? (
            <form encType="multipart/form-data">
              <Field type="file" id="photo" className="field" />
              <div className="buttonRow">
                <Button mr={2} onClick={() => setPicState(false)}>
                  Cancel
                </Button>
                <Button mr={2} onClick={handlePicSubmit}>
                  Update
                </Button>
                {picSubmitted && <Spinner title="loading" />}
              </div>
            </form>
          ) : (
            <div className="editButtonBox">
              <Button onClick={() => setPicState(true)}>
                Edit Profile Pic
              </Button>
              <Tooltip
                content="This picture is not a public profile image and is only used by the Masterclass team for admin purposes and choosing which members can attend limited space events"
                direction="right"
              >
                <FaQuestionCircle />
              </Tooltip>
            </div>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default Account

const styles = {
  ".accountPageContainer": {
    display: ["block", "flex"],
    justifyContent: ["space-between", null, "normal"],
  },
  "&.accountPage": {
    mb: 4,
    ".accountDetails": {
      width: ["100%", "40%", "50%"],
      pr: 3,
      a: {
        color: "muted",
      },
    },
    "& .picDetails": {
      width: ["100%", null, "50%"],
      py: [3, 1, 1],
      pl: [0, 1, 3],
    },
    "p.lead": {
      fontSize: 4,
      color: "muted",
    },
    ".invalid-feedback": {
      color: "red",
    },
    label: {
      fontSize: 3,
      fontWeight: 700,
    },
    ".field": {
      mb: 3,
    },
    "h3.fieldTitle": {
      mb: 2,
    },
    ".profilePic": {
      maxWidth: "500px",
      maxHeight: "350px",
      mb: 2,
      img: {
        maxWidth: "500px",
        maxHeight: "350px",
        objectFit: "cover",
      },
    },
    "& .editButtonBox": {
      display: "flex",
      "& .Tooltip-Wrapper": {
        ml: 2,
        "&:hover": {
          cursor: "pointer",
        },
        svg: {
          verticalAlign: "-0.16em",
        },
      },
    },
  },
  "& .buttonRow": {
    mt: 3,
    display: "flex",
    "& svg": {
      color: "accent",
      width: "28px",
      mx: 2,
      my: 0,
    },
  },
}
